import React, { Component } from 'react'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Link , Switch} from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Drawer from '@material-ui/core/Drawer';
import Page1 from './Page1';
import Page2 from './Page2';
import FunPage from './FunPage';
import movie1 from '../images/movie1.m4v';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ControlledExpansionPanels from './ControlledExpansionPanels';
import ReactPlayer from 'react-player'
const drawerWidth = 210;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#222',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: '#222',
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#222',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const routes = [
  {
    path: "/Page1",
    exact: true,
    main: Page1
  },
  {
    path: "/Page2",
    exact: true,
    main: Page2
  },  {
    path: "/FunPage",
    exact: true,
    main: FunPage
  }
];


export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <Router>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Portfolio - Scott Seaburn
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>

        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.sidebar}
            />
          ))}
     
        </Switch>

        <Link to="/Page1"  onClick={handleDrawerClose} style={{textDecoration: 'none', color: 'white'}} >
        <ListItem button  >      
          <ListItemText primary="Portfolio" />
        </ListItem>
        </Link >

        <Link to="/Page1"  onClick={handleDrawerClose} style={{textDecoration: 'none', color: 'white'}} >
        <ListItem button  >      
          <ListItemText primary="Products" />
        </ListItem>
        </Link >

        <Link to="/Page1"  onClick={handleDrawerClose} style={{textDecoration: 'none', color: 'white'}} >
        <ListItem button  >      
          <ListItemText primary="Systems" />
        </ListItem>
        </Link >

        <Link to="/Page1"  onClick={handleDrawerClose} style={{textDecoration: 'none', color: 'white'}} >
        <ListItem button  >      
          <ListItemText primary="Software" />
        </ListItem>
        </Link >

        <Link to="/Page1"  onClick={handleDrawerClose} style={{textDecoration: 'none', color: 'white'}} >
        <ListItem button  >      
          <ListItemText primary="Marketing" />
        </ListItem>
        </Link >

        <Link to="/Page1"  onClick={handleDrawerClose} style={{textDecoration: 'none', color: 'white'}} >
        <ListItem button  >      
          <ListItemText primary="IoT" />
        </ListItem>
        </Link >

        <Link to="/Page1"  onClick={handleDrawerClose} style={{textDecoration: 'none', color: 'white'}} >
        <ListItem button  >      
          <ListItemText primary="AgTech" />
        </ListItem>
        </Link >


        <Link to="/FunPage"  onClick={handleDrawerClose} style={{textDecoration: 'none', color: 'white'}} >
        <ListItem button  >      
          <ListItemText primary="For Fun" />
        </ListItem>
        </Link >

        {/* <Divider />
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider /> */}
        {/* <ControlledExpansionPanels /> */}
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
         {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.main}
            />
            ))}
        
      </main>
    </div>
    </Router>
  );
}