import CNC from '../images/CNC.jpg';
import pickandplace from '../images/pickandplace.jpg';
import router from '../images/router.jpg';
import printer1 from '../images/3dprinter1.jpg';
import pnpscreen from '../images/pnpscreen.jpg';
import scale from '../images/scale.jpg';
import guitarpicks from '../images/guitarpicks.jpg';
import harvestrecords from '../images/harvestrecords.png';
import hofarms from '../images/hofarms.png';
import iotbox from '../images/iotbox.jpg';

const Fun_cardlist = [

  {
    id: 1, 
    heading: "Mill Converted to CNC", 
    caption: "Grizzly Mill converted to CNC for light machining.", 
    img: CNC},
    {
    id: 3, 
    heading: "Router table", 
    caption: "Converted surplus engraving machine to CNC router", 
    img: router},
    {
    id: 2, 
    heading: "Homebrew Pick and Place", 
    caption: "Pick and Place using OpenCV and automatic tool change", 
    img: pickandplace},
  
    {
    id: 5, 
    heading: "PNP Screen", 
    caption: "G-Code based motion control with OpenCV vison system to help align, rotate and position parts.", 
    img: pnpscreen},
    {
      id: 7, 
      heading: " Guitar Picks", 
      caption: "For $2000 you too can print guitar picks!", 
      img: guitarpicks},
    {
    id: 4, 
    heading: "Homemade 3D Printer", 
    caption: "One of several homemade 3D printer designs.", 
    img: printer1},
    {
      id: 9, 
      heading: "Harvest Scale System", 
      caption: "Sytem for weighing produce in the field.  Adhoc WiFi network allows operator to configure system from phone.", 
      img: hofarms},
    
    {
    id: 6, 
    heading: "Harvest Scale System", 
    caption: "Scale attached to Raspberry Pi, Thermal Printer and iButton.", 
    img: scale},
    {
    id: 8, 
    heading: "Harvest Scale System", 
    caption: "Date and time, employee, crop, field and weight are all printed on thermal printer and stored in Postgres database.  Adhoc WiFi network allows operator to configure system from phone.", 
    img: harvestrecords},
    {
      id: 10, 
      heading: "IoT System", 
      caption: "Solar powered NEMA rated IoT sensor system using cellular data connection on Particle Electron module.", 
      img: iotbox},
  

    
  



];

export default Fun_cardlist;