import ALTM from '../images/ALTM_2.jpg';
import datamonitor from '../images/datamonitor.jpg';
import nfx1000 from '../images/nfx1000.jpg';
import IntelectPCB from '../images/IntelectPCB.jpg';
import Connie from '../images/Connie.png';
import IPWave from '../images/IPWave.png';
import IPWaveConsole from '../images/IPWaveConsole.png';
import Uniphy from '../images/Uniphy2.png';
import CAD from '../images/CAD.png';
import P3B from '../images/P3B.png';
import ENET from '../images/ENET.png';
import ADSOffice from '../images/ADSOffice.jpg';
import CNC from '../images/CNC.jpg';
import ArrayReadout from '../images/ArrayReadout.jpg';

const ADS_cardlist = [

  {
    id: 3, 
    heading: "Advanced Light Transmittance Meter", 
    caption: "Precision optical instrument used in every state and several countries for measuring light transmittance through automotive windows.", 
    img: ALTM},
    {
      id: 11, 
      heading: "Advanced Design Systems", 
      caption: "Provided services including design and manufacturing of electronic and software products and systems from Mililani Technology Park.  Customers included Intelect, Verifone, Adtech, University of Hawaii, U.S. Navy and others.  ADS established Hawaii's first and only printed circuit board fabrication facility.", 
      img: ADSOffice}, 
    {
      id: 10, 
      heading: "Ethernet Control Module for AX/4000 System", 
      caption: "Designed controller module for Spirent(Adtech) AX/4000 System.  Module design was based upon 486 class processor with custom configured BIOS. The module served as communications interface to rack of network test modules that provided traffic generation and analysis.  PCB CAD and system software was provided by Adtech.", 
      img: ENET},
    {
      id: 2, 
      heading: "Data Monitor", 
      caption: " Custom Data Aquisition System for Dole Pineapple Cannery.  Provide real time production efficiency and throughput data for 14 process lines.  Designed several systems for Dole, Maui Pine, and Del Monte for counting, weighing and sorting pineapples, cans and boxes.", 
      img: datamonitor},


];

export default ADS_cardlist;