import ALTM from '../images/ALTM_2.jpg';
import datamonitor from '../images/datamonitor.jpg';
import nfx1000 from '../images/nfx1000.jpg';
import IntelectPCB from '../images/IntelectPCB.jpg';
import Connie from '../images/Connie.png';
import IPWave from '../images/IPWave.png';
import IPWaveConsole from '../images/IPWaveConsole.png';
import Uniphy from '../images/Uniphy2.png';
import CAD from '../images/CAD.png';
import P3B from '../images/P3B.png';
import ENET from '../images/ENET.png';
import ADSOffice from '../images/ADSOffice.jpg';
import ArrayReadout from '../images/ArrayReadout.jpg';

const cardlist = [
  {
    id: 1, 
    heading: "NFX-1000  Network Impairment Emulator", 
    caption: `Real World impairments to emulate the WAN.  Delay, Loss, Bandwith Limit at line rate 1GbE in both directions.`, 
    img: nfx1000},
  
  {
    id: 3, 
    heading: "Advanced Light Transmittance Meter", 
    caption: "Precision optical instrument used in every state and several countries for measuring light transmittance through automotive windows.", 
    img: ALTM},
    {
      id: 11, 
      heading: "Advanced Design Systems", 
      caption: "Provided services including design and manufacturing of electronic and software products and systems from Mililani Technology Park.  Customers included Intelect, Verifone, Adtech, University of Hawaii, U.S. Navy and others.  ADS established Hawaii's first and only printed circuit board fabrication facility.", 
      img: ADSOffice},
    {
      id: 9, 
      heading: "Printed Circuit Board(PCB) CAD Designs", 
      caption: "Using Cadence, Orcad, Teradyne, Protel, Gerber Scientific, and many more.", 
      img: CAD},

    {
    id: 5, 
    heading: "NetworkFX ConNIE", 
    caption: "ConNIE stands for “Converged Network Impairment Emulator” and is a versatile yet easy to use system that allows you to test the behavior of any application that runs across a Wide Area Network (WAN) using ‘real world’ conditions.  Product was first to implement TIA-921 Network Model standard.  Attended TIA meetings and contributed to the development of the standard.", 
    img: Connie},
    {
      id: 8, 
      heading: "IPWave", 
      caption: "IPWave is a software application that runs on Windows and allows you to test the behavior of any application that runs across a Wide Area Network (WAN) using ‘real world’ conditions.  Applications such as VoIP, IPTV and streaming media are particularly sensitive to impairments such as packet loss, latency and jitter.   IPWave allows for testing applications in a controlled lab environment.", 
      img: IPWaveConsole},
    {
    id: 7, 
    heading: "Industry First 10GbE UniPHY", 
    caption: " As Product Manager for Spirent Communications was responsible for new advanced traffic generator/analyzer supporting 10G Ethernet(LAN and WAN versions) as well as OC-192.  Helped write submission that resulted in Best of Show award at Networld Interop.  Product also won Spirent Innovation Award. Wrote Marketing Requirements Doc(MRD) and coordinated with engineering, sales, and customers during development and product launch. ", 
    img: Uniphy},
    

    {
    id: 10, 
    heading: "Ethernet Control Module for AX/4000 System", 
    caption: "Designed controller module for Spirent(Adtech) AX/4000 System.  Module design was based upon 486 class processor with custom configured BIOS. The module served as communications interface to rack of network test modules that provided traffic generation and analysis.  PCB CAD and system software was provided by Adtech.", 
    img: ENET},
    {
      id: 2, 
      heading: "Data Monitor", 
      caption: " Custom Data Aquisition System for Dole Pineapple Cannery.  Provide real time production efficiency and throughput data for 14 process lines.  Designed several systems for Dole, Maui Pine, and Del Monte for counting, weighing and sorting pineapples, cans and boxes.", 
      img: datamonitor},


    {
    id: 12, 
    heading: "Telescope Array Readout For Smithsonian Observatory", 
    caption: "Advanced Design Systems manufactured and served as prime contractor for advanced readout system for Smithsonian Observatory.  Electronics designed by Peter Onaka.", 
    img: ArrayReadout},
    {
      id: 4, 
      heading: "Hawaii's First Printed Circuit Board Fab Facility", 
      caption: " Custom PCBs overnight!  Supported many Hawaii based high tech companies with circuit design, PCB CAD, fabrication, manufacturing and assembly.", 
      img: IntelectPCB},
    {
      id: 13, 
      heading: "Aviation Anti-submarine Warfare Technician (AX)", 
      caption: "Served in U.S. Navy as an Aviation Anti-submarine Warfare Technician maintaining electronic systems on P-3B Orion aircraft including VHF and HF radios, inertial navigation systems, LORAN, TACAN, radar, and other ASW(Anti-submarine warfare specific systems.  Honorable discharge and transition to Naval Reserves.", 
      img: P3B}

];

export default cardlist;