import React from 'react';
import Album from './components/Album';
import PersistentDrawerLeft from './components/PersistentDrawerLeft'
import './App.css';

function App() {
  return (
    <div className="App">
      <PersistentDrawerLeft />
      {/* <Album /> */}
      {/* <header className="App-header">
        New React Portfolio
      </header> */}

    </div>
  );
}

export default App;
